<template>
  <main>
      <head-com/>
      <div class="container mt-2">
        <div class="d-flex flex-column align-items-stretch flex-shrink-0 bg-white">
        <span class="fs-5 fw-semibold"> Geincca</span>
          <hr>
          <router-link :to="item.route" v-for="item in items1" :key="item.title"  class="btn btn-outline-primary mt-2">
              <div class="d-flex w-100 justify-content-between">
                <strong class="mb-1">{{ item.title }}</strong>
                <i :class="item.icon" class="text-muted mt-1 fs-3"></i>
              </div>
              <small class="d-flex w-100 justify-content-between">
                   {{ item.subtitle }}
              </small>
          </router-link>
          
        </div>
      </div>
      <food-com/>
  </main>
</template>

<script>
import HeadCom from '../layouts/HeadCom'
import FoodCom from '../layouts/FoodCom'

export default {
  name: "HomeCom",
  data(){
    return{
      items1: [
          { color : 'list-group-item-primary', icon: 'fa fa-users', title: 'Censo CocaCola', subtitle: 'Clientes del Sistema', route: '/clientes'},
          { color : 'list-group-item-success', icon: 'fa fa-boxes', title: 'Productos', subtitle: 'Lista de Precios', route: {path:'/productos'} },
          { color : 'list-group-item-warning', icon: 'fa fa-list', title: 'Inventario', subtitle: 'Opciones de las Aplicaiones', route: {path:'/home'} },
          { color : 'list-group-item-secondary', icon: 'fa fa-bar-chart', title: 'Mis Estadisticas', subtitle: 'Asignacion a Empresas', route: {path:'/home'} },
        ],
    }
  },
  components:{
    HeadCom,
    FoodCom
  },
  mounted(){
    this.checkUser()
  }
}
</script>