<template>
<main>
  <head-com/>
  <div class="container mt-3">
      <h3>Lista de Precios</h3>
      <table id="tabla1" class="table table-Light table-borderless">
        <thead>
          <tr>
            <th>Producto</th>
            <th>Precio Dolar</th>
            <th>Precio Bs</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="producto in productos" :key="producto.id">
            <td>{{producto.codprod}}-{{ producto.descripcion }}</td>
            <td>{{producto.preciodol}}</td>
            <td>{{producto.precio3}}</td>
          </tr>
        </tbody>
      </table>
  </div>
  <food-com/>
</main>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

import axios from 'axios'
import {Global} from '../../../shared/Global'
import HeadCom from '../../layouts/HeadCom'
import FoodCom from '../../layouts/FoodCom'

export default {
  name: "App",
  data(){
    return{
        productos:[],
    }
  },
  mounted(){
    this.checkUser()
    this.getProductos()
  },
  components:{
    HeadCom,
    FoodCom,
  },
  methods: {
    getProductos(){
        axios.get(Global.url+'productos',this.headRequest())
        .then(res=>{
            this.productos=res.data
            setTimeout(() => {
               $("#tabla1").DataTable(this.tablaDinamica(true,true,true))
            })
          }
        )
        .catch(function(error){
            console.log(error)
        })
    },
  }
}  
</script>