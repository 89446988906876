<template>
  <div>
    <nav class="navbar navbar-light bg-light">
    <div class="container mt-2">
        <router-link class="btn btn-outline-success btn-lg" type="button" to="/home"><i class="fa fa-home"></i></router-link>
        <button class="btn btn-outline-danger btn-lg pull-right" type="button" @click="logOutApp"><i class="fa fa-sign-out"></i></button>
    </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'HeadCom'
};
</script>