<template>
<footer class="text-center text-md-start bg-light text-muted mt-3">
  <div class="text-center p-2" style="background-color: rgba(0, 0, 0, 0.05);">
      <em>{{ usuario }}</em>
  </div>
</footer>
</template>

<script>
export default {
    name: 'FoodCom',
    data(){
        return{
            usuario : null,
        }
    },
    mounted(){
        this.usuario = localStorage.getItem('spx_use_p')
    }
}
</script>