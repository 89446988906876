import Vue from 'vue'
import App from './App.vue'
import router from './router'
import helper from './shared/Helper'
import {Global} from './shared/Global'
import axios from 'axios'
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import 'bootstrap/dist/js/bootstrap.min.js';
import Maska from 'maska'
import JsonExcel from "vue-json-excel";

Vue.component("downloadExcel", JsonExcel);
Vue.use(Maska)
 Vue.config.productionTip = false
 Vue.use(helper)

Vue.mixin({
  methods: {
      // verifica estatus usuario
      checkUser: function () {
        if(localStorage.getItem('spx_tok_p') && localStorage.getItem('spx_nam_p')){  
          axios.get(Global.url+'usuario/verifica',this.headRequest())
          .then(
          res=>{
              if(res.data.response!="ok"){
                this.logOutApp()
              }
          }
          )
          .catch(function(error){
              console.log(error)
              this.logOutApp()
          })
      }
      else{
        this.logOutApp()
      }
    }  
  },
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
