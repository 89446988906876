import Vue from 'vue'
import VueRouter from 'vue-router' // router

// importar  componentes de rutas
import LoginCom from '../components/views/LoginCom'
import ErrorCom from '../components/views/ErrorCom'
import HomeCom from '../components/views/HomeCom'
import ClienteCom from '../components/views/clientes/ClienteCom'
import EditClienteCom from '../components/views/clientes/EditClienteCom'
import ProductosCom from '../components/views/productos/ProductosCom'

// crear componente de rutas
Vue.component('LoginCom',LoginCom)
Vue.component('ErrorCom',ErrorCom)
Vue.component('HomeCom',HomeCom)
Vue.component('ClienteCom',ClienteCom)
Vue.component('EditClienteCom',EditClienteCom)
Vue.component('ProductosCom',ProductosCom)

// use de vue-router
Vue.use(VueRouter)

// definir rutas
const routes = [
  {path:'/login',component:LoginCom,name:'LoginCom'},
  {path:'/',redirect:'/login',name:'login'},
  {path:'*',component:ErrorCom,name:'errorcom'},
  {path:'/home',component:HomeCom,name:'home'},
  {path:'/clientes',component:ClienteCom,name:'clientecom'},
  {path:'/editclientes',component:EditClienteCom,name:'editclientes'},
  {path:'/productos',component:ProductosCom,name:'productos'},
]

// crear objeto router
const router = new VueRouter({
  routes,
  mode:'history'
})

export default router;
