<template>
    <main>
        <head-com/>
        <div class="container mt-2">
            <h3>Datos del Cliente</h3>
            <form @submit.prevent="submit()">
                <div class="row g-2">
                    <div class="form-floating col">
                        <select v-model="parametros.CodVend" class="form-control">
                            <option v-for="vendedor in vendedores" :key="vendedor.CodVend" :value="vendedor.CodVend">{{ vendedor.Descrip }}</option>
                        </select>
                        <label class="text-primary">Vendedor</label>
                    </div>
                    <div class="form-floating col">
                        <input v-model="parametros.FechaCenso"  type="date" class="form-control" required>
                        <label class="text-primary">Fecha Censo</label>
                    </div>
                    <div class="form-floating col">
                        <input v-model="parametros.Latitud" type="text" class="form-control" placeholder="Latitud" required v-maska="'##.######'" value="00.000000">
                        <label class="text-primary">Latitud</label>
                    </div>
                    <div class="form-floating col">
                        <input v-model="parametros.Longitud" type="text" class="form-control" placeholder="Longitud" required v-maska="'##.######'" value="00.000000">
                        <label class="text-primary">Longitud</label>
                    </div>
                </div>
                <div class="row g-2 mt-1">
                    <div class="form-floating col">
                        <input v-model="parametros.ID3" type="text" class="form-control" placeholder="Rif" required v-maska="{ mask: 'H#########', tokens: { 'H': { pattern: /[jJvV]/, uppercase: true }}}">
                        <label class="text-primary">Rif</label>
                    </div>
                    <div class="form-floating col">
                        <select v-model="parametros.TipoCli" class="form-control">
                            <option value="1">No Contribuyente</option>
                            <option value="0">Contribuyente</option>
                            <option value="4">Contribuyente Especial</option>
                        </select>
                        <label class="text-primary">Tipo Cliente</label>
                    </div>
                </div>
                <div class="row g-2 mt-1">
                    <div class="form-floating col">
                        <input v-model="parametros.Descrip" type="text" class="form-control" placeholder="Nombre Establecimiento" required minlength="5" maxlength="60" v-maska="{ mask: 'X*', tokens: { 'X': { pattern: /[a-zA-Z ñÑ.,0-9]/, uppercase: true }} }">
                        <label class="text-primary">Nombre Establecimiento</label>
                    </div>
                    <div class="form-floating col">
                        <input v-model="parametros.RazonSocial" type="text" class="form-control" placeholder="Razon Social" required minlength="5" maxlength="60" v-maska="{ mask: 'X*', tokens: { 'X': { pattern: /[a-zA-Z ñÑ.,0-9]/, uppercase: true }} }">
                        <label class="text-primary">Razon Social</label>
                    </div>
                </div>
                <div class="row g-2 mt-1">
                    <div class="form-floating col">
                        <input v-model="parametros.DireccionFiscal" type="text" class="form-control" placeholder="Direccion Fiscal" maxlength="120" v-maska="{ mask: 'X*', tokens: { 'X': { pattern: /[a-zA-Z ñÑ.,0-9]/, uppercase: true }} }">
                        <label class="text-primary">Direccion Fiscal</label>
                    </div>
                    <div class="form-floating col">
                        <input v-model="parametros.DireccionEntrega" type="text" class="form-control" placeholder="Direccion Entrega"  maxlength="120" v-maska="{ mask: 'X*', tokens: { 'X': { pattern: /[a-zA-Z ñÑ.,0-9]/, uppercase: true }} }">
                        <label class="text-primary">Direccion Entrega</label>
                    </div>
                </div>
                <div class="row g-2 mt-1">
                    <div class="form-floating col">
                        <input v-model="parametros.ReferenciaEntrega" type="text" class="form-control" placeholder="Referencia"  maxlength="40" v-maska="{ mask: 'X*', tokens: { 'X': { pattern: /[a-zA-Z ñÑ.,0-9]/, uppercase: true }} }">
                        <label class="text-primary">Referencia</label>
                    </div>
                    <div class="form-floating col">
                        <select v-model="parametros.Municipio" class="form-control">
                            <option v-for="municipio in municipios" :key="municipio.id" :value="municipio.Descripcion">{{ municipio.Descripcion }}</option>
                        </select>
                        <label class="text-primary">Municipio</label>
                    </div>
                    <div class="form-floating col">
                        <select v-model="parametros.CodZona" class="form-control">
                            <option v-for="zona in zonas" :key="zona.id" :value="zona.CodZona">{{ zona.Descripcion }}</option>
                        </select>
                        <label class="text-primary">Zona</label>
                    </div>
                </div>
                <div class="row g-2 mt-1">
                    <div class="form-floating col">
                        <input v-model="parametros.RepresentLeg" type="text" class="form-control" placeholder="Nombre Propietario"  maxlength="40" v-maska="{ mask: 'X*', tokens: { 'X': { pattern: /[a-zA-Z ñÑ]/, uppercase: true }} }">
                        <label class="text-primary">Nombre Propietario</label>
                    </div>
                    <div class="form-floating col">
                        <input v-model="parametros.TelefLeg" type="text" class="form-control" placeholder="Telefono Propietario" v-maska="'####-#######'">
                        <label class="text-primary">Telefono Propietario</label>
                    </div>
                    <div class="form-floating col">
                        <input v-model="parametros.MovilLeg" type="text" class="form-control" placeholder="Celular Propietario" v-maska="'####-#######'">
                        <label class="text-primary">Celular Propietario</label>
                    </div>
                </div>
                <div class="row g-2 mt-1">
                    <div class="form-floating col">
                        <input v-model="parametros.Represent" type="text" class="form-control" placeholder="Nombre Encargado"  maxlength="40" v-maska="{ mask: 'X*', tokens: { 'X': { pattern: /[a-zA-Z ñÑ]/, uppercase: true }} }">
                        <label class="text-primary">Nombre Encargado</label>
                    </div>
                    <div class="form-floating col">
                        <input v-model="parametros.Telef" type="text" class="form-control" placeholder="Telefono Encargado" v-maska="'####-#######'">
                        <label class="text-primary">Telefono Encargado</label>
                    </div>
                    <div class="form-floating col">
                        <input v-model="parametros.Movil" type="text" class="form-control" placeholder="Celular Encargado" v-maska="'####-#######'">
                        <label class="text-primary">Celular Encargado</label>
                    </div>
                </div>
                <div class="row g-2 mt-1">
                    <div class="form-floating col">
                        <input v-model="parametros.Email" type="email" class="form-control" placeholder="Correo Electronico"  maxlength="190">
                        <label class="text-primary">Correo Electronico</label>
                    </div>
                    <div class="form-floating col">
                        <select v-model="parametros.Canal" class="form-control">
                            <option v-for="canal in canales" :key="canal.id"  :value="canal.id">{{ canal.Descripcion }}</option>
                        </select>
                        <label class="text-primary">Canal</label>
                    </div>
                    <div class="form-floating col">
                        <select v-model="parametros.MetodoVenta" class="form-control">
                            <option value="PREVENTA">PREVENTA</option>
                            <option value="AUTOVENTA">AUTOVENTA</option>
                            <option value="TELEVENTA">TELEVENTA</option>
                            <option value="OTRO">OTRO</option>
                        </select>
                        <label class="text-primary">Metodo de Venta</label>
                    </div>
                </div>
                <div class="row g-2 mt-1">
                    <div class="form-floating col">
                        <select v-model="parametros.QuienVendeEmp" class="form-control">
                            <option value="VALERANA">VALERANA</option>
                            <option value="MAYORISTA">MAYORISTA</option>
                            <option value="SUPERMERCADO">REVENDEDOR</option>
                            <option value="OTRO">OTRO</option>
                        </select>
                        <label class="text-primary">Quien vende nuestro productos</label>
                    </div>
                    <div class="form-floating col">
                        <select v-model="parametros.QuienVendeCom" class="form-control">
                            <option value="PREVENTA">PREVENTA</option>
                            <option value="AUTOVENTA">AUTOVENTA</option>
                            <option value="OTRO">OTRO</option>
                        </select>
                        <label class="text-primary">Quien vende productos competencia</label>
                    </div>
                </div>
                <div class="row g-2 mt-1">
                    <label class="text-primary mt-1">Frecuencia de Visita</label>
                    <div class="form-check form-switch col">
                    <input v-model="parametros.flu_emp" class="form-check-input" type="checkbox" true-value="1" false-value="0">
                    <label class="form-check-label" for="flexSwitchCheckDefault">Lunes</label>
                    </div>
                    <div class="form-check form-switch col">
                    <input v-model="parametros.fma_emp" class="form-check-input" type="checkbox" true-value="1" false-value="0">
                    <label class="form-check-label" for="flexSwitchCheckDefault">Martes</label>
                    </div>
                    <div class="form-check form-switch col">
                    <input v-model="parametros.fmi_emp" class="form-check-input" type="checkbox" true-value="1" false-value="0">
                    <label class="form-check-label" for="flexSwitchCheckDefault">Miercoles</label>
                    </div>
                    <div class="form-check form-switch col">
                    <input v-model="parametros.fju_emp" class="form-check-input" type="checkbox" true-value="1" false-value="0">
                    <label class="form-check-label" for="flexSwitchCheckDefault">Jueves</label>
                    </div>
                    <div class="form-check form-switch col">
                    <input  v-model="parametros.fvi_emp" class="form-check-input" type="checkbox" true-value="1" false-value="0">
                    <label class="form-check-label" for="flexSwitchCheckDefault">Viernes</label>
                    </div>
                </div>
                <div class="row g-2 mt-1">
                    <label class="text-primary mt-1">Frecuencia de Visita Competencia</label>
                    <div class="form-check form-switch col">
                    <input v-model="parametros.flu_com" class="form-check-input" type="checkbox" true-value="1" false-value="0">
                    <label class="form-check-label" for="flexSwitchCheckDefault">Lunes</label>
                    </div>
                    <div class="form-check form-switch col">
                    <input v-model="parametros.fma_com" class="form-check-input" type="checkbox" true-value="1" false-value="0">
                    <label class="form-check-label" for="flexSwitchCheckDefault">Martes</label>
                    </div>
                    <div class="form-check form-switch col">
                    <input v-model="parametros.fmi_com" class="form-check-input" type="checkbox" true-value="1" false-value="0">
                    <label class="form-check-label" for="flexSwitchCheckDefault">Miercoles</label>
                    </div>
                    <div class="form-check form-switch col">
                    <input v-model="parametros.fju_com" class="form-check-input" type="checkbox" true-value="1" false-value="0">
                    <label class="form-check-label" for="flexSwitchCheckDefault">Jueves</label>
                    </div>
                    <div class="form-check form-switch col">
                    <input v-model="parametros.fvi_com" class="form-check-input" type="checkbox" true-value="1" false-value="0">
                    <label class="form-check-label" for="flexSwitchCheckDefault">Viernes</label>
                    </div>
                </div>
                <div class="row g-2 mt-1">
                    <label class="text-primary mt-1">Equipos Asignados</label>
                    <table class="table table-primary table-sm">
                        <thead>
                            <tr>
                                <th>Serial</th>
                                <th>Tipo</th>
                                <th>Notas</th>
                                <th><button  type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#ModalActivos"><i class="fa fa-plus"></i></button></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="activo in activos" :key="activo.id">
                                <td>{{ activo.serial }}</td>
                                <td>{{ activo.tipo }}</td>
                                <td>{{ activo.nota }}</td>
                                <td>
                                    <button @click="borraractivo(activo.id)" type="button" class="btn btn-danger btn-sm"><i class="fa fa-trash"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row g-2">
                    <div class="form-floating col">
                        <input v-model="parametros.Observaciones" type="text" class="form-control" placeholder="Observaciones"  maxlength="190" v-maska="{ mask: 'X*', tokens: { 'X': { pattern: /[a-zA-Z .,ñÑ]/, uppercase: true }} }">
                        <label class="text-primary">Observaciones</label>
                    </div>
                </div>
                <div class="row g-2 mt-1">
                    <div class="form-floating col">
                        <input v-model="parametros.Transcriptor" type="text" class="form-control" placeholder="Transcriptor"  disabled>
                        <label class="text-primary">Transcriptor</label>
                    </div>
                    <div class="form-floating col">
                        <select  v-model="parametros.Censista" class="form-control">
                            <option v-for="censista in censistas" :key="censista.id" :value="censista.nombre">{{ censista.nombre }}</option>
                        </select>
                        <label class="text-primary">Censista</label>
                    </div>
                    <div class="form-floating col">
                        <select v-model="parametros.Supervisor" class="form-control">
                            <option v-for="supervisor in supervisores" :key="supervisor.id" :value="supervisor.nombre">{{ supervisor.nombre }}</option>
                        </select>
                        <label class="text-primary">Supervisor</label>
                    </div>
                </div>
                 <hr>
                 <div class="d-grid gap-2">
                <button class="btn btn-primary" type="submit">Actualizar</button>
                <router-link to="clientes" class="btn btn-secondary" type="button" >Cancelar</router-link>
                </div>
            </form> 
            <!-- Modal -->
            <div class="modal fade" id="ModalActivos" tabindex="-1" aria-labelledby="ModalActivosLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="ModalActivosLabel">Asociar Activo</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-floating col mb-1">
                            <input v-model="nuevoactivo.serial" type="text" class="form-control" placeholder="Transcriptor" v-maska="'################'">
                            <label class="text-primary">Serial</label>
                        </div>
                        <div class="form-floating col mb-1">
                            <select v-model="nuevoactivo.tipo" class="form-control">
                                <option value="V1">V1</option>
                                <option value="V2">V2</option>
                                <option value="V3">V3</option>
                                <option value="E">E</option>
                                <option value="M">M</option>
                                <option value="O">O</option>
                            </select>
                            <label class="text-primary">tipo</label>
                        </div>
                        <div class="form-floating col">
                            <input v-model="nuevoactivo.nota" type="text" class="form-control" placeholder="Transcriptor" maxlength="40" v-maska="{ mask: 'X*', tokens: { 'X': { pattern: /[a-zA-Z ñÑ0-9]/, uppercase: true }} }">
                            <label class="text-primary">Nota</label>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button @click="asociaractivo()" type="button" class="btn btn-primary">Guardar</button>
                    </div>
                    </div>
                </div>
            </div>
            <!--- FIN MODAL --->
        </div>
        <food-com/>
    </main>
</template>

<script>
import HeadCom from "../../layouts/HeadCom.vue"
import FoodCom from "../../layouts/FoodCom.vue"
import axios from 'axios'
import {Global} from '../../../shared/Global'

export default {
    components : {
        HeadCom,
        FoodCom,
    },
    data(){
        return {
            id : null,
            vendedores : [],
            censistas : [],
            supervisores : [],
            canales : Global.data_canales,
            municipios : Global.data_municipios,
            zonas : [],
            activos : [],
            parametros:Global.par_clientes,
            nuevoactivo : {
                idcliente : null,
                serial : null,
                tipo : null,
                nota : null
            }
        }
    },
    mounted(){
        axios.get(Global.url+'vendedores',this.headRequest()).then(res =>{this.vendedores = res.data}).catch(function(error){console.log(error)})
        axios.get(Global.url+'usuario/censista',this.headRequest()).then(res =>{this.censistas = res.data}).catch(function(error){console.log(error)})
        axios.get(Global.url+'usuario/supervisor',this.headRequest()).then(res =>{this.supervisores = res.data}).catch(function(error){console.log(error)})
        // axios.get(Global.url+'canales',this.headRequest()).then(res =>{this.canales = res.data}).catch(function(error){console.log(error)})
        //axios.get(Global.url+'municipios',this.headRequest()).then(res =>{this.municipios = res.data}).catch(function(error){console.log(error)})
        axios.get(Global.url+'zonas',this.headRequest()).then(res =>{this.zonas = res.data}).catch(function(error){console.log(error)})
        this.id = this.$route.params.id
        this.nuevoactivo.idcliente = this.id
        axios.get(Global.url+'activoscliente/'+this.id,this.headRequest()).then(res =>{this.activos = res.data}).catch(function(error){console.log(error)})
        axios.get(Global.url+'clientes/'+this.id,this.headRequest())
        .then(res =>{
            this.parametros.ID3 = res.data.ID3,
            this.parametros.FechaCenso = res.data.FechaCenso,
            this.parametros.Censista = res.data.Censista,
            this.parametros.Transcriptor = localStorage.getItem('spx_use_p'),
            this.parametros.Supervisor = res.data.Supervisor,
            this.parametros.CodVend = res.data.CodVend,
            this.parametros.Longitud = res.data.Longitud
            this.parametros.Latitud = res.data.Latitud
            this.parametros.TipoCli = res.data.TipoCli
            this.parametros.Descrip = res.data.Descrip
            this.parametros.DireccionEntrega = res.data.DireccionEntrega
            this.parametros.ReferenciaEntrega = res.data.ReferenciaEntrega
            this.parametros.RazonSocial = res.data.RazonSocial
            this.parametros.DireccionFiscal = res.data.DireccionFiscal
            this.parametros.Pais = res.data.Pais
            this.parametros.Estado = res.data.Estado
            this.parametros.Municipio = res.data.Municipio
            this.parametros.CodZona = res.data.CodZona
            this.parametros.Represent = res.data.Represent
            this.parametros.Telef = res.data.Telef
            this.parametros.Movil = res.data.Movil
            this.parametros.RepresentLeg = res.data.RepresentLeg
            this.parametros.TelefLeg = res.data.TelefLeg
            this.parametros.MovilLeg = res.data.MovilLeg
            this.parametros.Email = res.data.Email
            this.parametros.Canal = res.data.Canal
            this.parametros.MetodoVenta = res.data.MetodoVenta
            this.parametros.flu_emp = res.data.flu_emp
            this.parametros.fma_emp = res.data.fma_emp
            this.parametros.fmi_emp = res.data.fmi_emp
            this.parametros.fju_emp = res.data.fju_emp
            this.parametros.fvi_emp = res.data.fvi_emp
            this.parametros.QuienVendeEmp = res.data.QuienVendeEmp
            this.parametros.QuienVendeCom = res.data.QuienVendeCom
            this.parametros.flu_com = res.data.flu_com
            this.parametros.fma_com = res.data.fma_com
            this.parametros.fmi_com = res.data.fmi_com
            this.parametros.fju_com = res.data.fju_com
            this.parametros.fvi_com = res.data.fvi_com
            this.parametros.Observaciones = res.data.Observaciones
        })
        .catch(function(error){
            console.log(error)
        })

    },
     methods:{
         submit () {
            axios.put(Global.url+'clientes/'+this.id,this.parametros,this.headRequest())
            .then(()=>{
                this.$router.push('/clientes')
            })
            .catch(function(error){
                console.log(error)
            })
        },
        asociaractivo(){
            console.log(this.nuevoactivo)
            axios.post(Global.url+'activoscliente',this.nuevoactivo,this.headRequest())
            .then(()=>{
                this.actualizaactivos()
            })
            .catch(function(error){
                console.log(error)
            })
        },
        actualizaactivos(){
            axios.get(Global.url+'activoscliente/'+this.id,this.headRequest()).then(res =>{this.activos = res.data}).catch(function(error){console.log(error)})
        },
        borraractivo(idactivo){
            axios.delete(Global.url+'activoscliente/'+idactivo,this.headRequest())
            .then(()=>{
                this.actualizaactivos()
            })
            .catch(function(error){console.log(error)})
        }
    }
}
</script>