<template>
  <main class="form-signin">
    <form @submit.prevent="submit">
      <center>
        <img class="mb-5 mt-4" src="../../assets/logo.png" alt="" width="300" height="80">
      </center>
      <alert-com :textomen="textomen" :colormen="colormen" @alcerrar="deshabilita" />
      <div class="form-floating">
        <input v-model="parametros.usuario" type="text" class="form-control" id="floatingInput" placeholder="name@example.com">
        <label for="floatingInput">Usuario</label>
      </div>
      <div class="form-floating mt-3">
        <input v-model="parametros.password" type="password" class="form-control" id="floatingPassword" placeholder="Password">
        <label for="floatingPassword">Password</label>
      </div>
      <hr>
      <button class="w-100 btn btn-lg btn-primary" type="submit">Ingresar</button>
      <p class="mt-5 mb-3 text-muted">&copy; 2022</p>
    </form>
  </main>
</template>

<script>
import axios from 'axios'
import {Global} from '../../shared/Global'
import AlertCom from '../layouts/AlertCom.vue'
export default {
  name: 'LoginCom',
    data(){
        return{
          textomen : false,
          colormen : '',
          parametros:{
              usuario : '',
              password : '',
          }
        }
    },
    components : {
      AlertCom
    },
    methods: {
      submit () {
         let config={
            headers:{
              'Content-Type' : 'aplication/json'
            }
          }
        axios.post(Global.url+'nrsrclassl',this.parametros,config)
        .then((response)=>{
            this.textomen=response.data.message
            if(response.data.response=='ok'){
                this.colormen='primary'
                localStorage.setItem('spx_tok_p',response.data.token)
                localStorage.setItem('spx_nam_p',response.data.usuario)
                localStorage.setItem('spx_use_p',response.data.nombre)
                 this.$router.push('/home')
            }
            else{
                 this.colormen='danger'
                console.log(this.textomen)
            }
        })
        .catch(function(error){
            console.log(error)
        })
      },
      deshabilita() {
         this.textomen = false
      }
    },
}
</script>

<style>
body {
  align-items: center;
  background-color: #f5f5f5;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
