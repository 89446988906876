export var  Global = {
    url: 'https://apigeincca.geincca.com/api/',
    dev_url: 'http://localhost:8081/geincca/public/api/',
    rep_clientes: {
        "ID" : "id",
        "RIF" : "ID3",
        "FECHA CENSO" : "FechaCenso",
        "LONGITUD" : "Longitud",
        "LATITUD" : "Latitud",
        "RAZON SOCIAL" : "RazonSocial",
        "NOMBRE ESTABLECIMIENTO": "Descrip",
        "DIRECCION FISCAL" : "DireccionFiscal",
        "DIRECCION ENTEGA" : "DireccionEntrega",
        "REFERENCIA" : "ReferenciaEntrega",
        "Censista": "Censista",
        "Transcriptor": "Transcriptor",
        "Supervisor": "Supervisor",
        "CodVend": "CodVend",
        "Observaciones": "Observaciones",
        "NOMBRE PROPIETARIO": "RepresentLeg",
        "TELEFONO PROPIETARIO": "TelefLeg",
        "CELULAR PROPIETARIO": "MovilLeg",
        "NOMBRE CONTACTO" : "Represent",
        "TELEFONO CONTACTO" : "Telef",
        "CELULAR CONTACTO" : "Movil",
        "Email": "Email",
        "METODO VENTA": "MetodoVenta",
        "FV LUNES" : "flu_emp",
        "FV MARTES" : "fma_emp",
        "FV MIERCOLES" : "fmi_emp",
        "FV JUEVES" : "fju_emp",
        "FV VIERNES" : "fvi_emp",
        "QUIEN VENDE" : "QuienVendeEmp",
        "QUIEN VENDE COMPETENCIA" : "QuienVendeCom",
        "FV COMPETENCIA LUNES" : "flu_com",
        "FV COMPETENCIA MARTES" : "fma_com",
        "FV COMPETENCIA MIERCOLES" : "fmi_com",
        "FV COMPETENCIA JUEVES" : "fju_com",
        "FV COMPETENCIA VIERNES" : "fvi_com",
        "TIPOCLIENTE" : "TipoCli",
        "PAIS" : "Pais",
        "ESTADO" : "Estado",
        "MUNICIPIO" : "Municipio",
        "ZONA" : "CodZona",
        "CANAL" : "Canal"
      },
      par_clientes : {
        ID3 : "",
        FechaCenso : "",
        Censista : "",
        Transcriptor : "",
        Supervisor : "",
        CodVend : "",
        Longitud : 0,
        Latitud : 0,
        TipoCli : 0,
        Descrip : "",
        DireccionEntrega : "",
        ReferenciaEntrega : "",
        RazonSocial : "",
        DireccionFiscal : "",
        Pais : 0,
        Estado : 0,
        Municipio : 0,
        CodZona : "",
        Represent : null,
        Telef : null,
        Movil : null,
        RepresentLeg : null,
        TelefLeg : null,
        MovilLeg : null,
        Email : null,
        Canal : 0,
        MetodoVenta : "",
        flu_emp : 0,
        fma_emp : 0,
        fmi_emp : 0,
        fju_emp : 0,
        fvi_emp : 0,
        QuienVendeEmp : "",
        QuienVendeCom : "",
        flu_com : 0,
        fma_com : 0,
        fmi_com : 0,
        fju_com : 0,
        fvi_com : 0,
        Observaciones : null,
    },
    data_canales : [
      {
        "id": 24,
        "Descripcion": " INSTALACIONES DE GOBIERNO/PUBLICAS"
      },
      {
        "id": 99,
        "Descripcion": "A LISTAR"
      },
      {
        "id": 2,
        "Descripcion": "ABASTOS"
      },
      {
        "id": 3,
        "Descripcion": "AEROLINEAS"
      },
      {
        "id": 501,
        "Descripcion": "AGENCIA DE LOTERIA"
      },
      {
        "id": 241,
        "Descripcion": "AGENCIAS DE FESTEJOS/CATERING"
      },
      {
        "id": 4,
        "Descripcion": "ASILO/HOGARES DE CUIDADO"
      },
      {
        "id": 5,
        "Descripcion": "AUTOLAVADO/REPUESTOS/TALLER"
      },
      {
        "id": 7,
        "Descripcion": "BINGO/CASINO"
      },
      {
        "id": 116,
        "Descripcion": "BODEGAS"
      },
      {
        "id": 9,
        "Descripcion": "CARNICERIA/PESCADERIA"
      },
      {
        "id": 172,
        "Descripcion": "CENTRO DE COMUNICACIONES/CYBERS CAFÉ"
      },
      {
        "id": 6,
        "Descripcion": "CENTRO NOCTURNO/"
      },
      {
        "id": 33,
        "Descripcion": "CINES/TEATROS"
      },
      {
        "id": 52,
        "Descripcion": "CLIENTE EVENTUAL/PORTERÍA"
      },
      {
        "id": 179,
        "Descripcion": "CLUB SOCIAL"
      },
      {
        "id": 207,
        "Descripcion": "COLEGIOS/LICEOS"
      },
      {
        "id": 999,
        "Descripcion": "CONCESIONARIOS"
      },
      {
        "id": 14,
        "Descripcion": "CONFITERÍA/DULCERÍA"
      },
      {
        "id": 234,
        "Descripcion": "DIST MAYORISTA"
      },
      {
        "id": 222,
        "Descripcion": "DISTRIBUIDOR INDEPENDIENTE"
      },
      {
        "id": 555,
        "Descripcion": "EMPLEADOS"
      },
      {
        "id": 16,
        "Descripcion": "ESTACIONAMIENTO"
      },
      {
        "id": 13,
        "Descripcion": "ESTADIO/CANCHAS/GYMNASIO"
      },
      {
        "id": 19,
        "Descripcion": "FARMACIA DE CADENA"
      },
      {
        "id": 132,
        "Descripcion": "FARMACIAS"
      },
      {
        "id": 17,
        "Descripcion": "FERIAS/EVENTOS/FIESTAS"
      },
      {
        "id": 888,
        "Descripcion": "FLETERO"
      },
      {
        "id": 20,
        "Descripcion": "FRUTERIA / VERDURAS"
      },
      {
        "id": 22,
        "Descripcion": "HELADERÍA"
      },
      {
        "id": 23,
        "Descripcion": "HIPERMERCADO"
      },
      {
        "id": 30,
        "Descripcion": "HOSPITALES/CLÍNICA"
      },
      {
        "id": 204,
        "Descripcion": "HOTEL/MOTEL/POSADA"
      },
      {
        "id": 43,
        "Descripcion": "INDUSTRIA / FÁBRICA/ COMEDOR INDUSTRIAL"
      },
      {
        "id": 41,
        "Descripcion": "KIOSKO COMIDA AMBULANTE"
      },
      {
        "id": 26,
        "Descripcion": "KIOSKO FIJO COMIDA "
      },
      {
        "id": 144,
        "Descripcion": "KIOSKO PERIODICOS"
      },
      {
        "id": 11,
        "Descripcion": "LICORERIAS"
      },
      {
        "id": 162,
        "Descripcion": "LUNCH/FTES SODA/AREPERAS/CAFET"
      },
      {
        "id": 666,
        "Descripcion": "MINIDEPOSITOS"
      },
      {
        "id": 27,
        "Descripcion": "OFICINA POSTAL/SERV. MENSAJERIA"
      },
      {
        "id": 28,
        "Descripcion": "OFICINAS PRIVADAS"
      },
      {
        "id": 8,
        "Descripcion": "OTROS"
      },
      {
        "id": 108,
        "Descripcion": "PANADERÍA/PASTERIA"
      },
      {
        "id": 34,
        "Descripcion": "PAPELERÍA/LIBRERÍA"
      },
      {
        "id": 35,
        "Descripcion": "PARQUE DE DIVERSIONES/CIRCOS"
      },
      {
        "id": 36,
        "Descripcion": "PELUQUERIA/BARBERIA"
      },
      {
        "id": 25,
        "Descripcion": "POLICIA/PRISION/BOMBEROS/FFAA"
      },
      {
        "id": 38,
        "Descripcion": "REST. COMIDA RAPIDA"
      },
      {
        "id": 37,
        "Descripcion": "RESTAURANTE LUJO"
      },
      {
        "id": 18,
        "Descripcion": "RESTAURANTES TRADICIONALES"
      },
      {
        "id": 118,
        "Descripcion": "REVENDEDORAS"
      },
      {
        "id": 45,
        "Descripcion": "SUP CADENAS  NACIONALES"
      },
      {
        "id": 101,
        "Descripcion": "SUP CADENAS REGIONALES"
      },
      {
        "id": 102,
        "Descripcion": "SUPERMERCADOS INDEPENDIENTES"
      },
      {
        "id": 48,
        "Descripcion": "TERMINAL DE AUTOBUSES/TAXIS/BUSETAS"
      },
      {
        "id": 12,
        "Descripcion": "TIENDA GASOLINERA"
      },
      {
        "id": 49,
        "Descripcion": "TIENDA POR DEPARTAMENTO/OFERTAS"
      },
      {
        "id": 29,
        "Descripcion": "UNIVERSIDADES Y TECNOLOGICOS"
      },
      {
        "id": 251,
        "Descripcion": "VENDEDORES  AMBULANTES"
      },
      {
        "id": 51,
        "Descripcion": "VENDING"
      },
      {
        "id": 53,
        "Descripcion": "VIDEO CLUB/JUEGOS"
      }
    ],
    data_municipios : [
      {
        "id": 1,
        "Descripcion": "Agua Blanca"
      },
      {
        "id": 2,
        "Descripcion": "Agua Santa"
      },
      {
        "id": 3,
        "Descripcion": "Agua Viva"
      },
      {
        "id": 4,
        "Descripcion": "Betijoque"
      },
      {
        "id": 5,
        "Descripcion": "Bocono"
      },
      {
        "id": 6,
        "Descripcion": "Bolivia"
      },
      {
        "id": 7,
        "Descripcion": "Buena Vista"
      },
      {
        "id": 8,
        "Descripcion": "Burbusay"
      },
      {
        "id": 9,
        "Descripcion": "Campo Elias"
      },
      {
        "id": 10,
        "Descripcion": "Carache"
      },
      {
        "id": 11,
        "Descripcion": "Carmona"
      },
      {
        "id": 12,
        "Descripcion": "Carmona"
      },
      {
        "id": 13,
        "Descripcion": "Casco Central"
      },
      {
        "id": 14,
        "Descripcion": "Centro"
      },
      {
        "id": 15,
        "Descripcion": "Chejende"
      },
      {
        "id": 16,
        "Descripcion": "Cuicas"
      },
      {
        "id": 17,
        "Descripcion": "El Alto Escuque"
      },
      {
        "id": 18,
        "Descripcion": "El Baqo"
      },
      {
        "id": 19,
        "Descripcion": "El Batatal"
      },
      {
        "id": 20,
        "Descripcion": "El Bolo"
      },
      {
        "id": 21,
        "Descripcion": "El Boqueron"
      },
      {
        "id": 22,
        "Descripcion": "El Cenizo"
      },
      {
        "id": 23,
        "Descripcion": "El Dividive"
      },
      {
        "id": 24,
        "Descripcion": "El Matacho"
      },
      {
        "id": 25,
        "Descripcion": "El Prado"
      },
      {
        "id": 26,
        "Descripcion": "El Socorro"
      },
      {
        "id": 27,
        "Descripcion": "Escuque"
      },
      {
        "id": 28,
        "Descripcion": "Isnotu"
      },
      {
        "id": 29,
        "Descripcion": "Jajo"
      },
      {
        "id": 30,
        "Descripcion": "La Cabaqa"
      },
      {
        "id": 31,
        "Descripcion": "La Candelaria"
      },
      {
        "id": 32,
        "Descripcion": "La Ceiba"
      },
      {
        "id": 33,
        "Descripcion": "La Concepcion De Carache"
      },
      {
        "id": 34,
        "Descripcion": "La Concepcion De Pampanito"
      },
      {
        "id": 35,
        "Descripcion": "La Cuchilla"
      },
      {
        "id": 36,
        "Descripcion": "La Floresta"
      },
      {
        "id": 37,
        "Descripcion": "La Marchantica"
      },
      {
        "id": 38,
        "Descripcion": "La Mata"
      },
      {
        "id": 39,
        "Descripcion": "La Mesa De Esnujaque"
      },
      {
        "id": 40,
        "Descripcion": "La Mulata"
      },
      {
        "id": 41,
        "Descripcion": "La Plata"
      },
      {
        "id": 43,
        "Descripcion": "La Plazuela"
      },
      {
        "id": 42,
        "Descripcion": "La Plazuela"
      },
      {
        "id": 44,
        "Descripcion": "La Plazuelita"
      },
      {
        "id": 45,
        "Descripcion": "La Puerta"
      },
      {
        "id": 46,
        "Descripcion": "La Quebrada"
      },
      {
        "id": 47,
        "Descripcion": "La Union"
      },
      {
        "id": 48,
        "Descripcion": "Las Acacias"
      },
      {
        "id": 49,
        "Descripcion": "Las Mesitas"
      },
      {
        "id": 50,
        "Descripcion": "Lazo De La Vega"
      },
      {
        "id": 51,
        "Descripcion": "Le Cejita"
      },
      {
        "id": 52,
        "Descripcion": "Los Caqos"
      },
      {
        "id": 53,
        "Descripcion": "Los Haticos"
      },
      {
        "id": 54,
        "Descripcion": "Los Pinos"
      },
      {
        "id": 55,
        "Descripcion": "Mendoza Fria"
      },
      {
        "id": 56,
        "Descripcion": "Mesa De Gallardo"
      },
      {
        "id": 57,
        "Descripcion": "Miton"
      },
      {
        "id": 58,
        "Descripcion": "Monay"
      },
      {
        "id": 59,
        "Descripcion": "Monte Carmelo"
      },
      {
        "id": 60,
        "Descripcion": "Motatan"
      },
      {
        "id": 61,
        "Descripcion": "Niquitao"
      },
      {
        "id": 62,
        "Descripcion": "Otras Poblaciones"
      },
      {
        "id": 63,
        "Descripcion": "Palmarito"
      },
      {
        "id": 64,
        "Descripcion": "Pampan"
      },
      {
        "id": 65,
        "Descripcion": "Pampanito"
      },
      {
        "id": 66,
        "Descripcion": "Sabana De Mendoza"
      },
      {
        "id": 67,
        "Descripcion": "Sabana Grande"
      },
      {
        "id": 68,
        "Descripcion": "Sabana Libre"
      },
      {
        "id": 69,
        "Descripcion": "Sabaneta"
      },
      {
        "id": 70,
        "Descripcion": "San Jacinto"
      },
      {
        "id": 71,
        "Descripcion": "San Jose De Palmira"
      },
      {
        "id": 72,
        "Descripcion": "San Juan De Betijoque"
      },
      {
        "id": 73,
        "Descripcion": "San Lazaro"
      },
      {
        "id": 74,
        "Descripcion": "San Lazaro"
      },
      {
        "id": 75,
        "Descripcion": "San Luis"
      },
      {
        "id": 77,
        "Descripcion": "San Miguel De Bocono"
      },
      {
        "id": 76,
        "Descripcion": "San Miguel De Bocono"
      },
      {
        "id": 78,
        "Descripcion": "San Rafael De Bocono"
      },
      {
        "id": 79,
        "Descripcion": "San Rafael De Bocono"
      },
      {
        "id": 80,
        "Descripcion": "San Rafael De Carvajal"
      },
      {
        "id": 81,
        "Descripcion": "Santa Ana"
      },
      {
        "id": 82,
        "Descripcion": "Santa Apolonia De Trujillo"
      },
      {
        "id": 83,
        "Descripcion": "Santa Isabel"
      },
      {
        "id": 85,
        "Descripcion": "Santa Rosa"
      },
      {
        "id": 84,
        "Descripcion": "Santa Rosa"
      },
      {
        "id": 86,
        "Descripcion": "Santiago"
      },
      {
        "id": 87,
        "Descripcion": "Sector Alto De Escuque"
      },
      {
        "id": 88,
        "Descripcion": "Sector Arbol Redondo"
      },
      {
        "id": 89,
        "Descripcion": "Sector Cerro Gordo"
      },
      {
        "id": 90,
        "Descripcion": "Sector Cuicas"
      },
      {
        "id": 91,
        "Descripcion": "Sector El Batatal"
      },
      {
        "id": 92,
        "Descripcion": "Sector El Giani"
      },
      {
        "id": 93,
        "Descripcion": "Sector El Socorro"
      },
      {
        "id": 94,
        "Descripcion": "Sector Jalisco"
      },
      {
        "id": 95,
        "Descripcion": "Sector La Concepcion De Carache"
      },
      {
        "id": 96,
        "Descripcion": "Sector La Cuchilla"
      },
      {
        "id": 97,
        "Descripcion": "Sector La Cuchilla"
      },
      {
        "id": 98,
        "Descripcion": "Sector La Mesa"
      },
      {
        "id": 99,
        "Descripcion": "Sector La Morita"
      },
      {
        "id": 100,
        "Descripcion": "Sector Las Acacias"
      },
      {
        "id": 101,
        "Descripcion": "Sector Los Granados"
      },
      {
        "id": 102,
        "Descripcion": "Sector Miton"
      },
      {
        "id": 103,
        "Descripcion": "Sector Torococo"
      },
      {
        "id": 104,
        "Descripcion": "Sector Tosto"
      },
      {
        "id": 105,
        "Descripcion": "Sector Valle Hondo"
      },
      {
        "id": 106,
        "Descripcion": "Torococo"
      },
      {
        "id": 107,
        "Descripcion": "Torondoy"
      },
      {
        "id": 108,
        "Descripcion": "Tostos"
      },
      {
        "id": 109,
        "Descripcion": "Tres Esquinas"
      },
      {
        "id": 110,
        "Descripcion": "Trujillo"
      },
      {
        "id": 111,
        "Descripcion": "Trujillo"
      },
      {
        "id": 112,
        "Descripcion": "Urb. Bella Vista"
      },
      {
        "id": 113,
        "Descripcion": "Urb. Burbusay"
      },
      {
        "id": 114,
        "Descripcion": "Urb. Campo Elias"
      },
      {
        "id": 115,
        "Descripcion": "Urb. La Beatriz"
      },
      {
        "id": 116,
        "Descripcion": "Urb. La Esperanza"
      },
      {
        "id": 117,
        "Descripcion": "Urb. Las Lomas"
      },
      {
        "id": 118,
        "Descripcion": "Urb. Moron"
      },
      {
        "id": 119,
        "Descripcion": "Urb. Parque Sur"
      },
      {
        "id": 120,
        "Descripcion": "Urb. Plata I"
      },
      {
        "id": 121,
        "Descripcion": "Urb. Plata Ii"
      },
      {
        "id": 122,
        "Descripcion": "Urb. Plata Iii"
      },
      {
        "id": 123,
        "Descripcion": "Urb. Plata Iv"
      },
      {
        "id": 124,
        "Descripcion": "Urb. San Antonio"
      },
      {
        "id": 125,
        "Descripcion": "Urb. San Rafael"
      },
      {
        "id": 126,
        "Descripcion": "Urb.jose Felix Rivas"
      },
      {
        "id": 127,
        "Descripcion": "Urbanizacion El Country"
      },
      {
        "id": 128,
        "Descripcion": "Urbanizacion El Recreo"
      },
      {
        "id": 129,
        "Descripcion": "Urbanizacion Pena Linares"
      },
      {
        "id": 130,
        "Descripcion": "Urbanizacion Santa Maria"
      },
      {
        "id": 131,
        "Descripcion": "Urbanizacion Valmore"
      },
      {
        "id": 132,
        "Descripcion": "Valera"
      }
    ]
}