<template>
<main>
  <head-com/>
  <div class="container mt-3">
        <div class="input-group mt-4 mb-2">
          <input v-model="datosbusqueda" type="search" class="form-control rounded" placeholder="INGRESE NOMBRE - RIF" aria-label="Search" aria-describedby="search-addon" />
          <button @click="getClientes()" type="button" class="btn btn-primary"><i class="fa fa-search"></i></button>
        </div>
      <hr>
      <div class="d-grid gap-2">
        <button class="btn btn-outline-success" type="button" data-bs-toggle="modal" data-bs-target="#ModalNuevo">NUEVO CLIENTE</button>
      </div>
      <hr>
      <table id="tabla1" class="table table-Light table-borderless">
        <thead>
          <tr>
          </tr>
        </thead>
        <tbody>
          <tr v-for="cliente in clientes" :key="cliente.id">
            <td>
             <div class="d-grid gap-2 col-12 mx-auto">
                <router-link class="btn btn-outline-primary" :to="{name:'editclientes',params:{id:cliente.id}}">
                  <div class="d-flex w-100 justify-content-between">
                    <b class="mb-1">{{ cliente.RazonSocial }}</b>
                  </div>
                  <small class="d-flex w-100 justify-content-between">
                   <i>{{ cliente.ID3 }} - {{cliente.DireccionFiscal }}</i>
                  </small>
                </router-link>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <hr>
      <download-excel
        class="btn btn-primary"
        :data="json_data"
        :fields="json_fields"
        worksheet="My Worksheet"
        name="censo_cocacola_valerana.xls"
      >
      Descargar Censo Completo
      </download-excel>
      <!-- Modal -->
            <div class="modal fade" id="ModalNuevo" tabindex="-1" aria-labelledby="ModalNuevoLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="ModalNuevoLabel">Nuevo Cliente</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <alert-com :textomen="textomen" :colormen="colormen" @alcerrar="deshabilita" />
                      <div class="form-floating col mb-1">
                        <input v-model="parametros.ID3" type="text" class="form-control" placeholder="Rif" required v-maska="{ mask: 'H#########', tokens: { 'H': { pattern: /[jJvV]/, uppercase: true }}}">
                        <label class="text-primary">Rif</label>
                      </div>
                      <div class="form-floating col mb-1">
                          <input v-model="parametros.RazonSocial" type="text" class="form-control" placeholder="Razon Social" required minlength="5" maxlength="60" v-maska="{ mask: 'X*', tokens: { 'X': { pattern: /[a-zA-Z ñÑ.,0-9]/, uppercase: true }} }">
                          <label class="text-primary">Razon Social</label>
                      </div>
                      <div class="form-floating col mb-1">
                          <input v-model="parametros.DireccionFiscal" type="text" class="form-control" placeholder="Direccion Fiscal" maxlength="120" v-maska="{ mask: 'X*', tokens: { 'X': { pattern: /[a-zA-Z ñÑ.,0-9]/, uppercase: true }} }">
                          <label class="text-primary">Direccion Fiscal</label>
                      </div>
                      <div class="form-floating col">
                        <select v-model="parametros.TipoCli" class="form-control">
                            <option value="1">No Contribuyente</option>
                            <option value="0">Contribuyente</option>
                            <option value="4">Contribuyente Especial</option>
                        </select>
                        <label class="text-primary">Tipo Cliente</label>
                    </div>
                    </div>
                    <div class="modal-footer">
                        <button @click="nuevocliente()" type="button" class="btn btn-primary">Crear Cliente</button>
                    </div>
                    </div>
                </div>
            </div>
            <!--- FIN MODAL --->
  </div>
  <food-com/>
</main>
</template>

<script>
// import "jquery/dist/jquery.min.js";
// import "datatables.net-dt/js/dataTables.dataTables";
// import "datatables.net-dt/css/jquery.dataTables.min.css";
// import $ from "jquery";

import axios from 'axios'
import {Global} from '../../../shared/Global'
import HeadCom from '../../layouts/HeadCom'
import FoodCom from '../../layouts/FoodCom'
import AlertCom from '../../layouts/AlertCom.vue'

export default {
  name: "App",
  data(){
    return{
      json_fields: Global.rep_clientes,
      json_data:[],
        textomen : false,
        colormen : '',
        parametros : {
          RazonSocial : null,
          DireccionFiscal : null,
          TipoCli : null
        },
        clientes:[],
        datosbusqueda : null,
        transcriptor : null
    }
  },
  mounted(){
    this.transcriptor = localStorage.getItem('spx_use_p')
    this.checkUser()
    this.getClientes()
    //
    axios.get(Global.url+'clientes',this.headRequest())
    .then(res=>{
        this.json_data=res.data
      }
    )
    .catch(function(error){
        console.log(error)
      })
  //
  },
  components:{
    HeadCom,
    FoodCom,
    AlertCom,
  },
  methods: {
    getClientes(){
      if(this.datosbusqueda){
        axios.get(Global.url+'clientesbusqueda/'+this.datosbusqueda,this.headRequest())
        .then(res=>{
            this.clientes=res.data
            // setTimeout(() => {
            //   $("#tabla1").DataTable(this.tablaDinamica(true,true,true))
            //  })
          }
        )
        .catch(function(error){
            console.log(error)
          })
      }
      else{
        axios.get(Global.url+'clientestranscriptor/'+this.transcriptor,this.headRequest())
        .then(res=>{
            this.clientes=res.data
          }
        )
        .catch(function(error){
            console.log(error)
          })
      }
    },
    nuevocliente(){
      axios.post(Global.url+'clientes',this.parametros,this.headRequest())
      .then(res=>{
          this.textomen=res.data.message
          this.colormen=res.data.colormen
          this.getClientes()
        }
      )
      .catch(function(error){
          console.log(error)
        })
    },
    deshabilita() {
         this.textomen = false
         this.colormen = ''
    }
  }
}  
</script>
